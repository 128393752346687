import $ from 'jquery';

$(document).ready(function () {
  console.log('slider');
  $('.slider-section').slick({
    cssEase: 'ease',
    fade: true,
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 0, // Prevent generating extra markup
    slide: '.slider-section__slide', // Cause trouble with responsive settings
  });
});
